import React from 'react'
import classNames from 'classnames'
import { Icon, Tooltip } from '@ebth/design-system'

import './inputfield.scss'

const InputField = ({
  errors = [],
  label,
  name,
  onChange,
  value = null,
  inputType = 'text',
  autocomplete = null,
  floatedLabel = false,
  fieldIcon = null,
  iconToolTip = null,
  ...otherProps
}) => {
  // TODO: Move state handling to parent component
  // const [val, setVal] = useState('')

  const hasErrors = errors.length > 0

  const groupClassNames = classNames('form-group', {
    'form-group--with-icon': fieldIcon,
    'form-group--show-floated-label': floatedLabel && !!value,
    'form-group--error': hasErrors,
  })

  const labelClassNames = classNames('form-group__label', {
    'form-group__label--floated': floatedLabel,
  })

  return (
    <div className={groupClassNames}>
      <label className={labelClassNames} htmlFor={name}>
        {label}
      </label>
      <input
        className="form-control__input input"
        id={name}
        name={name}
        type={inputType}
        placeholder={floatedLabel ? label : null}
        value={value}
        onChange={onChange}
        autoComplete={autocomplete}
        {...otherProps}
      />
      {!!fieldIcon && !iconToolTip && (
        <Icon
          icon={fieldIcon}
          className={`input-icon input-icon--${fieldIcon}`}
        />
      )}
      {!!fieldIcon && !!iconToolTip && (
        <Tooltip content={iconToolTip} placement="top-end">
          <Icon
            icon={fieldIcon}
            className={`input-icon input-icon--${fieldIcon}`}
          />
        </Tooltip>
      )}
      {hasErrors && <div className="form-group__error">{errors.join(',')}</div>}
    </div>
  )
}

export default InputField
